var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "stageline-landing-page-v1-component" } },
    [
      _vm.showNotification ? _c("notification-manager") : _vm._e(),
      _c("h1", { staticClass: "stageline-title mb-4 mt-5" }, [
        _vm._v("\n    Welcome to the Stageline™\n  "),
      ]),
      _c("div", { staticClass: "stageline-info" }, [
        _vm._v(
          "\n    Use our Stageline™ to help guide you through the various stages of starting and\n    maintaining your business. Whether you've already formed your business or are just\n    getting started, our free and easy-to-use tool is here to help you, one stage at a time.\n  "
        ),
      ]),
      _c(
        "b-container",
        [
          !_vm.loaded
            ? _c("ct-centered-spinner", {
                staticStyle: { "min-height": "200px" },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.loaded
        ? _c("div", { staticClass: "d-flex select-or-add-company" }, [
            _c("div", { attrs: { id: "image-left" } }, [
              _c("picture", [
                _c("source", {
                  attrs: {
                    srcset: require("@images/illustrations/filing-options-halfpage.webp"),
                    type: "image/webp",
                  },
                }),
                _c("img", {
                  staticClass: "image",
                  attrs: {
                    src: require("@images/illustrations/filing-options-halfpage.png"),
                    alt: "Woman relaxing in chair",
                    height: "408",
                  },
                }),
              ]),
            ]),
            _c(
              "div",
              { attrs: { id: "company-selection-right" } },
              [
                _vm.companyCount > 0
                  ? [
                      _vm.companyCount <= 5
                        ? _c(
                            "div",
                            { staticClass: "company-card-container" },
                            [
                              _c("selection-cards", {
                                attrs: {
                                  "button-text": "Continue",
                                  items: _vm.accountCompanies,
                                },
                                on: { chosen: _vm.view },
                              }),
                            ],
                            1
                          )
                        : _c("multi-select", {
                            attrs: {
                              value: _vm.selectedCompany,
                              options: _vm.accountCompanies,
                              searchable: true,
                              "allow-empty": false,
                              "track-by": "id",
                              label: "name",
                              placeholder: "Select a company",
                            },
                            on: { input: _vm.view },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "singleLabel",
                                  fn: function ({ option }) {
                                    return [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(option.name) +
                                          "\n          "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              84746447
                            ),
                          }),
                      _vm._m(0),
                    ]
                  : _vm._e(),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary text-nowrap",
                    on: { click: _vm.presentAddCompanyModal },
                  },
                  [
                    _vm.companyCount < 1
                      ? _c("span", [_vm._v("Create a New Business")])
                      : _c("span", [_vm._v("Create Another Business")]),
                  ]
                ),
              ],
              2
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "continue" },
        [
          _vm._v("\n    Continue to\n    "),
          _c("b-link", { attrs: { to: "/dashpanel" } }, [
            _vm._v("\n       Home\n    "),
          ]),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "add-company-modal",
          attrs: {
            "header-class": "no-borders",
            "hide-header": "",
            "hide-footer": "",
            size: "lg",
          },
          model: {
            value: _vm.showAddCompanyModal,
            callback: function ($$v) {
              _vm.showAddCompanyModal = $$v
            },
            expression: "showAddCompanyModal",
          },
        },
        [_c("add-company", { on: { create: _vm.createCompany } })],
        1
      ),
      _c("formation-info-modal", {
        ref: "formation-info-modal",
        attrs: { company: _vm.company, "load-products": false },
        on: {
          "formation-info-modal-complete":
            _vm.formationInfoModalCompleteHandler,
        },
        model: {
          value: _vm.companyAdded,
          callback: function ($$v) {
            _vm.companyAdded = $$v
          },
          expression: "companyAdded",
        },
      }),
      _c("company-domestic-registration-modal", {
        ref: "company-domestic-registration-modal",
        attrs: { company: _vm.company, "load-products": false },
        on: {
          "domestic-registration-changed":
            _vm.domesticRegistrationChangedHandler,
        },
      }),
      _c("capture-odd-data"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row-or" }, [_c("div", [_vm._v("or")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }