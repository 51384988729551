var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "formation-info-modal",
      attrs: {
        id: "formation-info-modal",
        "ok-disabled": _vm.valid,
        disabled: _vm.valid,
        "hide-header": "",
        "header-class": "no-borders",
        size: "md",
        "ok-title": "Continue",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-header-close": "",
      },
      on: { ok: _vm.submitCompanyFormationInfo },
    },
    [
      _c(
        "b-container",
        { staticClass: "container-fluid max-width" },
        [
          _c("h3", { staticClass: "mt-0" }, [
            _vm._v("\n      Company Formation:\n    "),
          ]),
          _c("br"),
          _c(
            "b-form-group",
            { attrs: { label: "Have you already formed your company?" } },
            [
              _c(
                "b-form-radio",
                {
                  attrs: { name: "company-formed", value: false },
                  model: {
                    value: _vm.companyFormed,
                    callback: function ($$v) {
                      _vm.companyFormed = $$v
                    },
                    expression: "companyFormed",
                  },
                },
                [_vm._v("\n        No, I would like to form a company\n      ")]
              ),
              _c(
                "b-form-radio",
                {
                  attrs: { name: "company-formed", value: true },
                  model: {
                    value: _vm.companyFormed,
                    callback: function ($$v) {
                      _vm.companyFormed = $$v
                    },
                    expression: "companyFormed",
                  },
                },
                [_vm._v("\n        Yes, I already have a company\n      ")]
              ),
            ],
            1
          ),
          _vm.companyFormed
            ? _c(
                "b-form-group",
                { attrs: { label: "What is your Company's formation Date?" } },
                [
                  _c("b-form-datepicker", {
                    staticClass: "mb-2",
                    attrs: {
                      id: "formation-date",
                      max: _vm.maxDate,
                      state: true,
                      placeholder: "Choose a formation date",
                      "today-button": "",
                      "reset-button": "",
                      "close-button": "",
                    },
                    model: {
                      value: _vm.formationDate,
                      callback: function ($$v) {
                        _vm.formationDate = $$v
                      },
                      expression: "formationDate",
                    },
                  }),
                  _c("div", { staticClass: "row-or" }, [
                    _c("div", [_vm._v("\n          or\n        ")]),
                  ]),
                  _c(
                    "b-form-radio",
                    {
                      attrs: { value: "unknown" },
                      model: {
                        value: _vm.formationDate,
                        callback: function ($$v) {
                          _vm.formationDate = $$v
                        },
                        expression: "formationDate",
                      },
                    },
                    [
                      _vm._v(
                        "\n        I can't remember my formation date.\n      "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }